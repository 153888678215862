.SpecialPricingSearchForm {
  background-color: #fff;
  margin-bottom: 1rem !important;
  border: 1px solid #f0f0f0;

  .search-area {
    margin-bottom: 0;
  }

  .form-title {
    padding: 1.5rem 0.2rem;
  }

  .ant-card {
    margin-bottom: 0.5rem;
    background: transparent;

    .ant-card-body {
      padding: 0.5rem 1rem;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-form-item-label {
    label {
      padding-left: 0.5rem;
      padding-top: 0.5rem;
    }
  }

  .filter-area {
    padding: 0.8rem;
    border-bottom: 1px solid #f0f0f0;
    background: rgba(#f0f0f0, 0.3);
    width: 100%;

    .ant-select-selector {
      border-radius: 20px !important;
      width: 160px !important ;
      padding-left: 0.5rem;
    }

    .ant-picker {
      border-radius: 20px !important;
    }
  }
}
