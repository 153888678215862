@import 'variables';

.Suppliers {
  .search-box {
    .ant-col {
      &:first-child {
        margin-left: 0 !important;
      }
      margin-left: 10px !important;
    }

    .float-right {
      float: right;
      button {
        float: right;
      }
    }

    margin-bottom: 30px;
  }

  .ant-table-thead {
    tr {
      th {
        text-align: center;
      }
      th:first-child {
        padding-left: 40px;
        text-align: left;
      }
    }
  }

  .ant-table-tbody {
    tr {
      td {
        text-align: center;
      }
      td:first-child {
        padding-left: 40px;
        text-align: left;
      }
    }
  }

  .ant-table-tbody {
    tr {
      td {
        p {
          margin-bottom: 0;
          line-height: 27px;
        }
      }
    }
  }
}

.SupplierDetail {
  font-size: 13px;
  .detail-section {
    margin: 0 30px 30px 10px;
    padding: 20px;
    background-color: #fff;
    border: solid 1px #e9e9e9;
    h3 {
      font-weight: 900;
    }
    .ant-row {
      .ant-col-12 {
        padding-right: 5%;
        label {
          width: 160px;
          display: inline-block;
          font-weight: bolder;
          line-height: 50px;
        }
        > div {
          width: calc(100% - 160px);
          display: inline-block;
          p {
            margin-left: 10px;
            display: inline-block;
          }
        }
      }
      .close-button {
        padding: 0 10px;
        font-size: 18px;
        vertical-align: middle;
      }
      .judge {
        width: 70px;
        height: 36px;
        vertical-align: middle;
        margin-right: 8px;
        font-size: 13px;
        font-weight: 600;
        border-radius: 4px;
        background-color: transparent;
      }
      .judge-reject {
        color: #ff5353;
        border: 1px solid #ffa6a6;
      }
      .judge-confirm {
        color: #1aba00;
        border: 1px solid #7fcb73;
      }
    }
  }
  .footer {
    margin: 0 30px 0 10px;
    button {
      width: 118px;
      height: 36px;
      font-size: 13px;
      border-radius: 4px;
    }
    .cancel {
      color: #666;
      border: 1px solid #bbb;
      background-color: #fff;
    }
    .edit {
      background-color: #3055ff;
      border: 0;
    }
  }
}

.float-left {
  float: left;
}
.float-right {
  float: right;
}
