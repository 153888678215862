@import 'variables';

$plain-gutter: 4px;

.CouponScheme {
  .search-box {
    .ant-col {
      &:first-child {
        margin-left: 0 !important;
      }
      margin-left: 10px !important;
    }

    .float-right {
      float: right;
      button {
        float: right;
      }
    }

    margin-bottom: 30px;
  }
}
