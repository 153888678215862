@import 'variables';

.SpecialPricingRegistration {
  .page-header {
    height: 40px;
    padding: 0 0.2rem 0.4rem;
    h1 {
      font-size: 1.1rem;
      color: #333;
    }
  }

  .result-count {
    padding: 0rem 1rem 0.2rem;
    font-size: 0.8rem;
  }
}
