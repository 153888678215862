// font
$fonts-fallback: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
  'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

$fonts-fallback-code: 'source-code-pro', Menlo, Monaco, Consolas, 'Courier New', monospace;

// colors
$color-light-gray: #fafafa;
$color-border-gray: #ddd;
