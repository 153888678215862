@import 'variables';

$plain-gutter: 4px;

.TripPlannerReservation {
  .page-header {
    padding: 0 0.25rem 0.5rem;
    h1 {
      font-size: 1.2rem;
      color: #333;
    }
  }

  .TripPlannerReservationSearchForm {
    .search-period {
      input {
        width: 45%;
      }
      input:nth-child(2) {
        width: 10%;
        text-align: center;
        background-color: #ffff;
      }
    }
  }

  .table-button-box {
    button {
      margin-left: 3px;
      font-size: 0.75rem;
      padding: 2px 12px;
    }
    button:first-child {
      margin-left: 0;
    }
  }

  .ant-row .ant-legacy-form-item {
    margin-bottom: 0px !important;
  }
}
