@import url('https://cdn.rawgit.com/innks/NanumSquareRound/master/nanumsquareround.min.css');
@import 'variables';

body {
  margin: 0;
  font-family: 'NanumSquareRound', $fonts-fallback;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: $fonts-fallback-code;
}
